<template>
<!-- <div class="overlay" v-if="visibile"> -->
  <v-overlay class="overlay" :value="visibile" fixed>
  <div class="progress">
    <v-progress-circular
      indeterminate
      color="shaza_light_blue"
      size="200"
      width="3"
    ></v-progress-circular>
    <div class="msg">
      <div class="white--text" style="text-transform: uppercase;">{{cfg.msg}}</div>
    </div>
    
  </div> 
  </v-overlay>
<!-- </div> -->
</template>

<script>
  export default {
    computed:{
      visibile:{
        get(){
          return this.$store.getters['uiMod/getBufferOverlayVisibility'];
        },
        set(v){
          this.$store.dispatch('uiMod/setBufferOverlayVisibility', v)
        }
      },
      cfg(){
        return this.$store.getters['uiMod/getBufferOverlayCfg'];
      }
    }
  }
  
</script>

<style lang="scss" scoped>
  .overlay{
    text-align: center;
    z-index: 1000 !important;
  }
  .progress{
    width: 200px;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0,0,0,0.5);
    border-radius: 50%;
    .msg {
      width: 200px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-wrap: break-word;
      div{
        font-weight: bolder;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        overflow-wrap: break-word;
        max-width: 135px;
        margin: auto;
        max-height: 135px;
        overflow: auto;
      }
    }
  }
</style>